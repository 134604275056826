import React, { useState, useEffect } from "react"
import Layout from "@/components/layout"
import { SEO } from "@/components"
import { header_bg } from '@/images/pioneers/index'
import style from './index.module.less'
import { useGTMInit} from '@/hooks/useGTM.js'
import { LookingFor, Perks, ShouldBe, Join } from '@/new_components/Pioneers/index'

function Index() {
    useEffect(()=>{
        useGTMInit('pioneers','pioneers')
      },[])
    return (
        <Layout menuBlack={false}>
            <SEO page="Pioneers" />
            <div className={style.header} style={{ backgroundImage: `url(${header_bg})` }}>
                <div className={style.headerTitle}>XREAL Pioneers Program</div>
                <div className={style.headerDesc}>At XREAL, our mission is to bring AR to everyone. An important part of that is our commitment to building meaningful connections with the incredible people that make up the XREAL Community.  That's why we developed the XREAL Pioneers program—a platform designed to recognize and celebrate those who bring exceptional value to the XREAL Community, and in turn, helping them grow their voices in the AR space.</div>
            </div>
            <div className={style.box}>
                <div className={style.title}>What is the XREAL Pioneers Program?</div>
                <div className={style.desc}>
                    The XREAL Pioneers Program is all about highlighting individuals who are at the forefront of AR technology and innovation. XREAL Pioneers share their genuine insights and experiences, helping to communicate their knowledge of the AR/XR space and how XREAL’s products fit into their lifestyle.
                </div>

            </div>
            <LookingFor />
            <div className={style.title} style={{ padding: "0 20px" }}>XREAL Pioneer Perks</div>
            <Perks />
            <div className={style.box}>
                <div className={style.title}>Join the XREAL Pioneer Program</div>
                <Join />
            </div>

            <div className={style.box}>
                <div className={style.title}>As an XREAL Pioneer<br />You Will</div>
                <ShouldBe />
            </div>
            <div className={style.box2}>
                <p>XREAL Pioneers is voluntary program, and is our way of celebrating and validating efforts from the XREAL Community!</p>
                <p>We look forward to working with enthusiastic, future-thinkers and seeing the impact they will make as part of the XREAL Pioneers program!</p>
                <p>To learn more about the program, including limits and availability , please email us at <a href="mailto:info@xreal.com">info@xreal.com</a>.</p>
            </div>



        </Layout>
    )
}

export default Index
