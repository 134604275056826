import React from 'react'
import style from './index.module.less'
import {
    join_svg_1,
    join_svg_2,
    join_svg_3,
    join_svg_4,
    join_svg_5
} from '@/images/pioneers/index'
const Join = () => {
    const list = [
        {
            title: "Submit Application",
            desc: "Fill out the application form on the XREAL website.",
            img: join_svg_1
        },
        {
            title: "Manual Review",
            desc: "The XREAL team will review your application.",
            img: join_svg_2
        }, {
            title: "Approval",
            desc: "Once your application has been approved, you will receive confirmation.",
            img: join_svg_3
        }, {
            title: "Sign Agreement",
            desc: "A representative from XREAL will reach out to you to finalize the agreement, including a non-disclosure agreement related to unreleased information you may learn.",
            img: join_svg_4
        }, {
            title: "Complete Registration",
            desc: "After signing, you will officially become an XREAL Pioneer!",
            img: join_svg_5
        }
    ]
    return (
        <div className={style.content}>
            <div className={style.box}>
                {list.map((item, index) => (
                    <div key={`pioneers_join_${index}`} className={style.listItem}>
                        <div className={style.pic}>
                            <img src={item.img} />
                        </div>
                        <div className={style.text}>
                            <div className={style.title}>
                                {item.title}
                            </div>
                            <div className={style.desc}>{item.desc}</div>
                        </div>

                    </div>
                ))}
            </div>
            <div className={style.btn} onClick={() => window.open('https://xreal.feishu.cn/share/base/form/shrcn2f5R8IfINevbCBbUnikAOV')}>APPLY NOW</div>
        </div>

    )
}

export default Join